import { Injectable } from '@angular/core';
import { ApiHttpService, ApiIndexResult, ApiSingleResult } from '@capturum/api';
import { ListProject, Project } from '../models/project.model';
import { Observable } from 'rxjs';
import { GeneralApiService } from '@shared/services/general-api.service';
import { MapItem } from '@capturum/ui/api';
import { ListOptions } from '@capturum/api/lib/interfaces/api.interface';

@Injectable()
export class ProjectApiService extends GeneralApiService<Project> {
  protected endpoint: string = 'project';

  constructor(public apiHttpService: ApiHttpService) {
    super(apiHttpService);
  }

  public getByCompany(companyId?: string): Observable<MapItem[]> {
    return this.getItemsAsListOptions({
      filters: companyId ? [{ field: 'company_id', value: companyId }] : null,
    });
  }

  public rate(projectId: string, rate: number): Observable<ApiSingleResult<Project>> {
    const url = `/${this.endpoint}/${projectId}/rate`;

    return this.apiHttp.post<ApiSingleResult<Project>>(url, { rate });
  }

  public getFile(): Observable<Blob> {
    const url = `/functional-design`;

    return this.apiHttp.getBlob(url, 'pdf');
  }

  public getProjectList(options?: ListOptions): Observable<ApiIndexResult<ListProject>> {
    let url = `/${this.endpoint}/list`;

    if (options) {
      url += this.getOptionsQuery(options);
    }

    return this.apiHttp.get(url);
  }

  public linkUsersToProject(projectId: string, data: {
    user_ids: string[]
  }, options?: ListOptions): Observable<Project> {
    let url = `/${this.endpoint}/${projectId}/user/add`;

    if (options) {
      url += this.getOptionsQuery(options);
    }

    return this.apiHttp.post<Project>(url, data);
  }

  public unlinkUserFromProject(projectId: string, userId: string): Observable<Project> {
    const url = `/${this.endpoint}/${projectId}/user/${userId}/delete`;

    return this.apiHttp.delete(url);
  }
}
